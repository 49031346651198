<template>
    <div class="table">
        <div :class="['topbar', { expanded: isExpanded }]">
            <div class="sales-information"></div>

            <div class="content">
                <div class="navBar">
                    <button class="nav left" @click="scrollLeft"></button>

                    <div class="hours" ref="scrollContainer1">
                        <div v-for="(value, hour) in summary_hours" :key="hour" class="nav-elements">
                            <div
                                class="timeframe"
                                :class="{
                                    current: currentTimeframe == hour
                                }"
                            >
                                <span style="cursor: pointer;" @click="printProductsByHour(hour)" class="title">{{ hour }}</span>
                            </div>
                        </div>
                    </div>
                    <button class="nav right" @click="scrollRight"></button>
                </div>
            </div>
        </div>
        <div class="content-sections hide-scrollbar" ref="scrollContainer4">
            <div class="product-content">
                <div class="products">
                    <div class="item" v-for="product in filteredProducts" :key="product.id">
                        <div class="icon" :style="{ backgroundImage: 'url(' + product.image + ')' }"></div>
                        <div class="content">
                            <div class="name">
                                {{ product.name }}
                            </div>
                            <div class="container">{{ product.containers[product.active_container] ? product.containers[product.active_container].name : '' }}</div>
                        </div>
                    </div>
                </div>
                <div class="content">
                    <div class="forecast">
                        <div class="item" v-for="product in filteredProducts" :key="product.id" ref="scrollContainer">
                            <span style="cursor: pointer;" @click="printProduct(product.id,hour)" v-for="(value, hour) in summary_hours" :key="hour" class="value" :class="{ current: currentTimeframe == hour, null: getTotal(product.id)[hour] == '-' }">{{ getTotal(product.id)[hour] }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { indexOf } from 'lodash'

export default {
    name: 'temperingView',
    data() {
        return {
            isExpanded: false,
            start_hour: 0,
            max_hours: 4
        }
    },
    props: {
        products: {
            type: Array
        },
        date: { type: String }
    },
    computed: {
        forecast() {
            return this.$store.getters['production/getForecast']
        },
        currentTimeframe() {
            if (moment().isSame(this.date, 'day')) {
                var hour = Math.floor(moment().hour() / 2) * 2 + ':00'
                return hour
            }
            return false
        },
        summary_hours() {
            var hours = {}
            let position = 0
            let inserted = 0
            if (Object.keys(this.forecast.products.tempering).length > 0) {
                for (var idx in Object.values(this.forecast.products.tempering)[0].hours) {
                    if (position >= this.start_hour && inserted < this.max_hours) {
                        hours[moment(idx).format('HH:mm')] = 0
                        inserted += 1
                    }
                    position += 1
                }
            }

            return hours
        },
        summary_hours_complete() {
            var hours = {}
            if (Object.keys(this.forecast.products.tempering).length > 0) {
                for (var idx in Object.values(this.forecast.products.tempering)[0].hours) {
                    hours[moment(idx).format('HH:mm')] = 0
                }
            }
            return hours
        },
        filteredProducts() {
            return this.products.filter((product) => [3, 5].includes(product.type))
        }
    },
    methods: {
        roundConditional(value, units) {
            const factor = value / units < 1 ? 0.1 : 0.25
            return Math.round(Math.ceil(value / units / factor) * factor * 100) / 100
        },
        getTotal(productId) {
            var product = this.filteredProducts.find((item) => item.id === productId)

            var units = product.containers[product.active_container].units
            var factor = 0.25
            var result = {}
            if (this.forecast && this.forecast.products && typeof this.forecast.products.tempering[productId] !== 'undefined') {
                for (var idx in this.forecast.products.tempering[productId].hours) {
                    var key = moment(idx).format('HH:mm')
                    factor = this.forecast.products.tempering[productId].hours[idx] / units < 1 ? 0.1 : 0.25
                    // console.warn('factor', factor, 'units', units, 'hours', this.forecast.products.tempering[productId].hours[idx])
                    result[key] = this.roundConditional(this.forecast.products.tempering[productId].hours[idx], units)
                    if (result[key] == 0) {
                        result[key] = '-'
                    }
                }
                result.total = this.roundConditional(this.forecast.products.tempering[productId].total, units)
                // round total to 2 decimals maximum
            } else {
                for (var idx in this.summary_hours) {
                    result[idx] = '-'
                }
                result.total = '-'
            }

            return result
        },
        scrollLeft() {
            var value = this.start_hour - 1
            if (value < 0) {
                value = 0
            }
            this.start_hour = value
        },
        scrollRight() {
            var value = this.start_hour + 1
            if (value + this.max_hours > 11) {
                value = 11 - this.max_hours
            }
            this.start_hour = value
        },
        loadForecast() {
            let self = this
            this.$overlay.loading()
            this.$store.dispatch('production/loadForecast', this.date).then(() => {
                self.$overlay.hide()
            })
        },

        printProductsByHour(hour) {
            var self = this
            let references = []
            this.filteredProducts.forEach((product) => {
                let value = self.getTotal(product.id)[hour]
                value = value == '-' || value == '' ? 0 : value
                if(value > 0){
                    references.push(
                        {
                            id: product.id,
                            state_id: product.label.state,
                            quantity: value,
                            package: "400gr"
                        }
                    )
                }
            })
            log(references)
            this.$popup.confirm({
                message: 'Has seleccionado la franja horaria de tiempo <b>' + hour + '</b>. ¿Deseas imprimir las etiquetas para esta hora?',
                textSave: 'Imprimir',
                textCancel: 'Cancelar',
                callSave: function () {
                    self.$store.dispatch('label/prepareTemporalCollection', references).then(response =>{
                        log(response)
                        self.$router.push({ name: 'Label', params: {'print': true, 'type': 'collection', 'id': response.id} })
                    })
                }
            })
        },

        printProduct(id, timeframe){
            var self = this
            var value = this.getTotal(id)[timeframe]
            var product = this.filteredProducts.find((item) => item.id === id)
            if(product.length > 0){
                product = product[0]
            }

            let references =[
                {
                    id: product.id,
                    state_id: product.label.state,
                    quantity: value,
                    package: "400gr"
                }
            ]

            this.$popup.confirm({
                message: 'Vas a imprimir ' + value + ' etiquetas de <b>' + product.name + '</b> (podrás modificar la cantidad) ¿Deseas imprimir las etiquetas?',
                textSave: 'Imprimir',
                textCancel: 'Cancelar',
                callSave: function () {
                    self.$store.dispatch('label/prepareTemporalCollection', references).then(response =>{
                        log(response)
                        self.$router.push({ name: 'Label', params: {'print': true, 'type': 'collection', 'id': response.id} })
                    })
                }
            })
        }
    },
    created() {
        var hour = this.currentTimeframe
        if (hour) {
            this.start_hour = indexOf(Object.keys(this.summary_hours_complete), hour) - 1
        }
    }
}
</script>
<style lang="scss" scoped>
.table {
    display: flex;
    flex-direction: column;
    height: 100%;

    .positive {
        font-family: $text-bold !important;
        color: $color-success-500;
    }
    .negative {
        font-family: $text-bold !important;
        color: $color-error-500;
    }

    .topbar {
        width: 100%;
        height: 10%;
        display: flex;
        align-items: center;
        overflow: hidden;

        .content {
            display: flex;
            width: calc(70% + 30px);
            justify-content: flex-end;
            overflow-x: auto;
        }

        button {
            height: 50px;
            width: 50px;
            font-size: 30px;
            background-color: #fff;
        }
        .sales-information {
            width: calc(30% - 30px);
            display: flex;
            flex-direction: row;
            justify-content: left;
            align-items: center;
            flex-shrink: 0;
            height: 50px;
            gap: 8px;

            .nav {
                border-radius: 4px;
                display: flex;
                align-self: flex-start;
                width: 50px;
                height: 50px;
                align-items: center;
                &.sales-button {
                    @include background($image: img('chart_inactive.svg'), $size: 20px, $color: #fff);
                }
                &.active {
                    &.sales-button {
                        @include background($image: img('chart_active.svg'), $size: 20px, $color: $main);
                    }
                }
                &.mail {
                    @include background($image: img('mail.svg'), $size: 25px, $color: #fff);
                }
            }
        }
        .navBar {
            width: calc(100% - 100px);
            display: flex;
            justify-items: start;
            grid-template-columns: repeat(3, 1fr);
            gap: 10px;

            .nav-elements {
                //width: 250px;
                @include display-flex();
                @include align-items();
                gap: 8px;
                width: 100%;

                &.total {
                    width: 125px;
                    align-items: center;
                    justify-content: center;
                }

                .printer {
                    border-radius: 4px;
                    display: flex;
                    align-self: flex-start;
                    width: 50px;
                    height: 50px;
                    align-items: center;
                    @include background($image: img('printer_b3b3b3.svg'), $size: 20px, $color: #fff);
                }
                .timeframe {
                    @include display-flex();
                    flex-direction: column;
                    align-items: center;
                    width: 100%;
                    padding: 4px 0;
                    border-radius: 4px;

                    &.current {
                        background: rgba($color-warning-500, 1);

                        .title {
                            color: #fff;
                        }
                        .subtitles {
                            .subtitle {
                                color: #fff;
                            }
                        }
                    }

                    .title {
                        text-transform: uppercase;
                        color: $color-black;
                        @include font-size(m);
                        font-family: $text-bold;
                    }

                    .description {
                        color: $color-neutral-600;
                        @include font-size(xs);
                        font-family: $text;
                        line-height: 14px;
                    }
                }
            }

            .nav {
                border-radius: 4px;
                width: 50px;
                height: 50px;

                &.left {
                    @include background($image: img('left_dark.svg'), $size: 20px, $color: #fff);
                }
                &.right {
                    @include background($image: img('right_dark.svg'), $size: 20px, $color: #fff);
                }
            }
            .hours {
                width: calc(100% - 100px);
                height: 50px;
                display: flex;
                flex-direction: row;
                align-items: flex-end;
                overflow-x: hidden;
                scroll-behavior: smooth;
                gap: 10px;
                padding: 0 0px;

                display: grid;
                justify-items: start;
                grid-template-columns: repeat(4, 1fr);
                align-items: center;
                justify-items: center;

                .scroll-item {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex: 0 0 80px;
                    height: 100%;
                    width: 100px;
                    max-width: 100px;
                    min-width: 100px;
                    margin-left: 5px;

                    p {
                        font-family: $text;
                        font-size: 20px;
                        color: $color-neutral-600;
                    }
                }
                & .selected {
                    border: 2px solid $color-warning-500;
                    p {
                        font-family: $text-bold;
                    }
                }
            }
        }
        .summary-title {
            text-align: center;
            width: 125px;
            flex-shrink: 0;

            span {
                word-wrap: break-word;
                @include font-size(m);
                color: $color-neutral-600;
                font-family: $text-bold;
                text-transform: uppercase;
            }
        }
    }
    .content-sections {
        height: 90%;
        overflow: auto;
        .sales-section {
            width: 100%;
            display: flex;
            flex-direction: row;
            .sales-text {
                width: 30%;
                padding-left: 40px;
                span {
                    display: block;
                    font-family: $text;
                    display: flex;
                    align-items: center;
                    height: 35px;
                }
            }
            .sales-content {
                width: 70%;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                box-sizing: border-box;
                overflow: hidden;
                .sales-forecast {
                    width: calc(100% - 100px);
                    display: grid;
                    flex-direction: column;
                    grid-template-rows: repeat(4, 1fr);
                    align-items: center;
                    overflow-x: hidden;
                    overflow-y: hidden;
                    scroll-behavior: smooth;

                    .row {
                        display: grid;
                        flex-direction: row;
                        gap: 25px;
                        width: 100%;
                        grid-template-columns: repeat(3, 1fr);
                        text-align: center;

                        span {
                            text-align: center;
                        }

                        .scroll-item {
                            flex: 0 0 80px;
                            height: 35px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 100px;
                            max-width: 100px;
                            min-width: 100px;
                            margin-left: 5px;

                            span {
                                text-align: center;
                                width: 100px;
                                font-family: $text;

                                &.positive {
                                    font-family: $text-bold;
                                    color: $color-success-500;
                                }
                                &.negative {
                                    font-family: $text-bold;
                                    color: $color-error-500;
                                }
                            }
                        }
                    }
                }
            }
            .sales-summary {
                width: 125px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;
                span {
                    font-family: $text;
                    display: flex;
                    align-items: center;
                    height: 35px;
                }
            }
        }
        .product-content {
            display: flex;
            flex-direction: row;
            width: 100%;
            margin-top: 16px;

            .products {
                width: 30%;
                display: flex;
                flex-direction: column;
                gap: 10px;

                .item {
                    width: 100%;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    &:hover {
                        background-color: rgba(255, 255, 255, 0.6);
                    }
                    &.frost {
                        &:hover {
                            background-color: $main-t90;
                        }
                    }

                    .icon {
                        width: 35px;
                        height: 35px;
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: 90%;
                        display: inline-block;
                    }
                    .content {
                        flex-direction: column;
                        width: 100%;
                        padding-left: 8px;
                        .name {
                            display: flex;
                            align-items: center;
                            font-family: $text-bold;
                            text-transform: uppercase;
                            @include font-size(sm);
                        }
                        .icon {
                            /* background-image: img('/img/defrost.svg'); */
                            height: 25px;
                            width: 25px;
                        }
                        .container {
                            display: block;
                            font-family: $text;
                            color: $color-neutral-600;
                            display: block;
                        }
                    }
                }
            }

            .content {
                display: flex;
                width: 70%;
                justify-content: flex-end;

                .forecast {
                    width: calc(100% - 190px);
                    margin-right: 60px;
                    display: flex;
                    flex-direction: column;
                    align-content: center;
                    align-items: stretch;
                    flex-direction: column;
                    gap: 10px;

                    .item {
                        height: 50px;
                        display: grid;
                        grid-template-columns: repeat(4, 1fr);
                        flex-direction: row;
                        align-items: center;
                        overflow-x: hidden;
                        scroll-behavior: smooth;
                        padding: 0;
                        gap: 5px;
                        width: 100%;

                        .value {
                            font-family: $text;
                            @include font-size(m);
                            display: block;
                            height: 45px;
                            background: #fff;
                            border: 2px solid #ddd;
                            border-radius: 3px;
                            @include display-flex();
                            @include align-items();
                            @include justify-content();
                            position: relative;
                            //width: 250px;

                            &.total {
                                font-family: $text-bold;
                                margin-left: 10px;
                            }
                            &.started {
                                // background-color: $color-error-500;
                                border-color: $color-error-500;
                                font-family: $text-bold;
                                color: $color-error-500;
                            }
                            &.completed {
                                border-color: $color-success-500;
                                // color: $color-success-500;
                                // font-family: $text-bold;
                            }
                            &.null {
                                border-color: #ddd;
                                font-family: $text;
                            }

                            .increase {
                                display: block;
                                position: absolute;
                                bottom: 0px;
                                @include font-size(xs);
                                display: flex;
                                align-items: center;

                                &:before {
                                    display: inline-block;
                                    content: '';
                                    width: 0;
                                    height: 0;
                                    border-left: 5px solid transparent;
                                    border-right: 5px solid transparent;
                                    border-radius: 3px;
                                    margin-right: 2px;
                                }

                                &.positive {
                                    &:before {
                                        border-bottom: 6px solid green;
                                    }
                                }
                                &.negative {
                                    &:before {
                                        border-top: 6px solid #f00;
                                    }
                                }
                            }

                            &.current {
                                // background-color: $color-warning-500;
                                // border-color: $color-warning-500;
                                // font-family: $text-bold;
                                // color: #fff;

                                .value {
                                    color: #fff;
                                }
                                .increase {
                                    color: #fff;
                                }
                            }
                        }
                    }
                }
            }
            .summary {
                width: 125px;
                text-align: center;
                .item {
                    width: 100%;
                    height: 75px;
                    display: flex;
                    align-items: center;
                    justify-content: space-around;

                    .value {
                        font-family: $text-bold;
                        @include font-size(l);
                        text-transform: uppercase;
                        text-align: center;
                    }
                }
            }
        }
    }
}

//RBI DEVICE
@media (max-width: 1285px) {
    .table {
        .topbar {
            .navBar {
                .nav-elements {
                    .timeframe {
                        .title {
                            @include font-size(sm);
                        }
                        .description {
                            @include font-size(xs);
                        }
                    }
                    .printer {
                        width: 40px;
                        height: 40px;
                    }
                }
            }
        }
    }
}
</style>
